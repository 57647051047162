@media (max-width:1400px) {

  .h1,
  h1 {
    font-size: 2rem;
  }

  .h2,
  h2 {
    font-size: 1.7rem;
  }

  .h3,
  h3 {
    font-size: 1.4rem;
  }

  .h4,
  h4 {
    font-size: 1.3rem;
  }

  .h5,
  h5 {
    font-size: 1.1rem;
  }

  .h6,
  h6 {
    font-size: 1rem;
  }

  .title {
    font-size: 2.8rem;

    &--big{
      font-size: 3.5rem;
    }

    &--semi{
      font-size: 3rem;
    }

    &--medium {
      font-size: 2.2rem;
    }

    &--smaller {
      font-size: 1.8rem;
    }
  }

  .text--big{
    font-size: 1.1rem;
  }
}
