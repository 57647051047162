@mixin efekt($what: all) {
  transition: $what .25s ease-in-out;
}

.efekt {
  transition: all 0.25s ease-in-out;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-start{
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}

.bdrs {
  border-radius: 6px;
}

.dikeyAbsolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.dikeyFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bxs {
  box-shadow: 0 2px 6px $color_black, 0 2px 6px $color_black;
}

.bxsh {
  transition: all 0.2s ease-in-out;

  &:Hover {
    box-shadow: 0 10px 22px $color_black, 0 10px 10px $color_black;
  }
}

.bgcover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fw400{
  font-weight: 400;
}

.fw500{
  font-weight: 500;
}

.fw600{
  font-weight: 600;
}

.fw700{
  font-weight: 700;
}

.fz-12{
  font-size: 12px;
}

.fz-13{
  font-size: 13px;
}

.fz-14{
  font-size: 14px;
}

.fz-15{
  font-size: 15px;
}

.fz-16{
  font-size: 16px;
}

.fz-17{
  font-size: 17px;
}

.fz-18{
  font-size: 18px;
}

.fz-19{
  font-size: 19px;
}

.fz-20{
  font-size: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb50{
  margin-bottom: 50px;
}

.mb60{
  margin-bottom: 60px;
}

.mb75{
  margin-bottom: 75px;
}

.mb100{
  margin-bottom: 100px;
}

.mb150{
  margin-bottom: 150px;
}

.mt15 {
  margin-top: 15px;
}

.mt30 {
  margin-top: 30px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt75 {
  margin-top: 75px;
}

.mt100 {
  margin-top: 100px;
}

.mt150{
  margin-top: 150px;
}

.visible-scrollbar{
  &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 2px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color_black;
      border-radius: 10px;
      border: 1px solid $color_black;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: $color_black;
    }
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color_black;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color_black;
  }
}

.scrollbar-yatay {
  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: $color_black;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color_black;
  }
}
