@media (max-width: 992px) {
  .thumb-item{
    font-size: .9rem;
  }

  .slider-detail{
    left: 60px;
  }

  .title {
    font-size: 2.4rem;

    &--big {
      font-size: 2.8rem;
    }

    &--semi {
      font-size: 2.6rem;
    }

    &--medium {
      font-size: 1.9rem;
    }

    &--smaller {
      font-size: 1.6rem;
    }
  }

  .home-about__img{
    height: 40vh;
    width: 100%;
  }

  .home-about__content{
    width: 100%;
    padding: 30px 15px;
  }

  .news-block__left{
    width: 100%;
    border-right: none;
    position: relative;
    top: auto;
  }

  .news-block__content{
    width: 100%;
    border-left: none;
    margin-top: 30px;
    padding-left: 0;
  }

  .news-list__item{
    padding-left: 0;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }

  .h1,
  h1 {
    font-size: 1.7rem;
  }

  .h2,
  h2 {
    font-size: 1.5rem;
  }

  .h3,
  h3 {
    font-size: 1.3rem;
  }

  .h4,
  h4 {
    font-size: 1.2rem;
  }

  .h5,
  h5 {
    font-size: 1.1rem;
  }

  .h6,
  h6 {
    font-size: 1rem;
  }

  .contact-form{
    padding: 0;
  }

  .home-form--contact{
    margin-top: 50px;
  }
  .address-box__link--big{
    font-size: 1.6rem;
  }
}
