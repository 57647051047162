@import 'reset', 'colors', 'mixin', 'global', 'hamburgers', 'bootstrap', 'swiper', 'typography', 'buttons', 'forms';

#__bs_notify__ {
  display: none !important;
}

.arama-bg,
.mobil-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  display: none;
  width: 100%;
  height: 100%;
  background: $color_black;
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.arka-active {
  display: block;
}

.swiper-slide {
  height: auto;

  img {
    width: 100%;
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
  animation: fadeIn 0.5s;
}

.dropdown-menu {
  min-width: inherit;
  font-size: 0.9rem;

  a {
    text-decoration: none;
  }
}

.header {
  &__top {
    .container-fluid {
      @extend .flex;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $line;
    }
  }

  &__bottom {
    .container-fluid {
      @extend .flex;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $line;
    }
  }
}

.social {
  @extend .flex;

  &__link {
    @include efekt(color);
    display: inline-block;
    margin-right: 14px;
    color: $primary;

    &:hover {
      color: $hover;
    }
  }
}

.top-buttons {
  @extend .flex;
  padding-left: 0;
  list-style-type: none;

  &__item {
    margin-left: 25px;
  }

  &__link {
    @include efekt(color);
    color: $secondary;
    text-decoration: none;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9rem;

    .bi {
      margin-right: 2px;
      color: $primary;
    }

    &:hover {
      color: $primary;
    }
  }

  .dropdown-menu {
    left: -14px;
  }
}

.logo {
  display: inline-block;
  width: 109px;
  text-decoration: none;
}

.menu {
  &__list {
    @extend .flex;
    margin: 0;
    padding-left: 0;
    list-style-type: none;
  }

  &__item {
    margin-left: 35px;
    position: relative;
  }

  &__link {
    @include efekt(color);
    color: $secondary;
    text-decoration: none;
    text-decoration: none;
    font-weight: 600;

    &:after {
      color: $primary;
    }

    &:hover {
      color: $primary;
    }
  }
}

.menu__item:hover{
  & > .sub-menu{
    position: absolute;
    left: 0;
    width: 300px;
    padding: 15px;
    @extend .bdrs;
    @extend .bxs;
    top: 66px;
    background: #fff;
    z-index: 4;
    display: block;

    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: -50px;
      width: 100%;
      height: 50px;
      background: transparent;
    }
  }

  .sub-menu{
    a{
      color: $text;
      text-decoration: none;
      @include efekt(color);
      padding: 6px 0;

      &:hover{
        color: $primary;
      }
    }

    .open-next{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.custom-button {
  @extend .dikeyAbsolute;
  @include efekt(opacity);
  z-index: 10;
  color: #fff;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &--prev {
    left: 15px;
  }

  &--next {
    right: 15px;
  }

  .bi {
    width: 46px;
    height: 46px;
  }
}

.home-slider-wrapper {
  position: relative;
}

.thumb-slider {
  position: absolute;
  bottom: 15px;
  left: 0;
  z-index: 4;
  padding: 0 30px;
  width: 100%;

  .swiper-slide-thumb-active {
    .thumb-item {
      border-color: $primary;
      color: $primary;
    }
  }
}

.thumb-item {
  @extend .efekt;
  @extend .flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #fff;
  color: #fff;
  font-weight: 300;
  cursor: pointer;
  user-select: none;

  &__title {
    overflow: hidden;
    width: calc(100% - 40px);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__number {
    width: 40px;
    text-align: right;
  }

  &:Hover {
    border-color: $primary;
    color: $primary;
  }
}

.home-slider {
  .swiper-slide {
    overflow: hidden;

    img {
      min-height: 70vh;
      width: 100%;
      object-fit: cover;
    }
  }
}

.slider-detail {
  @extend .dikeyAbsolute;
  left: 120px;
  z-index: 4;
  width: 100%;
  color: #fff;

  &__title {
    display: block;
    margin-bottom: 15px;
    max-width: 100%;
    width: 650px;
    font-size: 3rem;
  }

  &__text {
    display: block;
    margin-bottom: 25px;
    max-width: 100%;
    width: 650px;
    font-size: 1.1rem;
  }
}

.icon-link {
  @include efekt(color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $secondary;
  text-decoration: none;
  font-weight: 600;

  .bi {
    margin-right: 6px;
    width: 24px;
    height: 24px;
    color: $primary;
  }

  &:Hover {
    color: $hover;
  }

  &--white {
    color: #fff;

    .bi {
      color: $primary;
    }

    &:hover {
      color: $primary;
    }
  }

  &--whiter {
    color: #fff;

    .bi {
      color: #fff;
    }

    &:hover {
      color: $primary;

      .bi {
        color: $primary;
      }
    }
  }
}

.title-block {
  &__small {
    color: $primary;
    font-weight: 400;
    font-size: 1rem;
  }
}

.text {
  font-size: 1rem;
  line-height: 1.7;

  &--big {
    font-size: 1.2rem;
  }
}

.service {
  @extend .bdrs;
  position: relative;
  padding: 24px 24px 60px;
  height: 100%;
  border: 1px solid $line;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: rgba($primary, .1);

    img{
      width: 42px;
    }
  }

  &__title {
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
    color: $secondary;
    font-size: 1.2rem;
  }

  &__text {
    display: block;
    margin-bottom: 25px;
  }

  .icon-link {
    position: absolute;
    bottom: 24px;
    left: 24px;
  }

  .flex{
    .icon-link{
      &:last-child{
        left: auto;
        right: 24px;
      }
    }
  }
}

.home-about {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__img {
    width: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding: 140px 60px;
    width: 50%;
    background: #f4f4f4;
  }
}

.home-news {}

.news-block {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  &__left {
    position: sticky;
    top: 50px;
    width: 40%;

    .title {
      margin-bottom: 25px;
      padding-right: 50px;
      padding-bottom: 25px;
      border-bottom: 1px solid $line;
    }

    .text {
      padding-right: 50px;
    }
  }

  &__content {
    width: 60%;
    border-left: 1px solid $line;
  }
}

.news-list {
  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 25px;
    padding-bottom: 25px;
    padding-left: 50px;
    border-bottom: 1px solid $line;

    &--big {
      display: block;

      .news-list__content {
        width: 100%;

        .text {
          font-size: 1rem;
        }
      }

      .news-list__title {
        font-size: 1.5rem;
      }

      .news-list__img {
        margin-bottom: 10px;
        width: 100%;

        a {
          height: auto;
        }

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }

  &__img {
    width: 200px;

    a {
      display: block;
      margin-bottom: 4px;
      height: 200px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__date {}

  &__content {
    width: calc(100% - 230px);
  }

  &__title {
    @include efekt(color);
    display: block;
    margin-bottom: 10px;
    color: $secondary;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.2rem;
  }

  .text {
    margin-bottom: 25px;
    font-size: 0.9rem;
  }
}

.home-banner {
  padding: 120px 0;
  color: #fff;
}

.form-box {
  @extend .bdrs;
  position: relative;
  padding: 60px 30px 30px;
  border: 1px solid $line;

  &__title {
    position: absolute;
    top: -20px;
    left: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    height: 40px;
    border: 1px solid $line;
    border-radius: 32px;
    background: #fff;
    font-weight: 400;
    font-size: 0.9rem;
  }
}

.form-label {
  display: block;
  margin-bottom: 6px;
  font-size: 0.9rem;
}

.custom-control-label {
  padding-top: 2px;
  font-size: 0.9rem;
}

.custom-control--box {
  @extend .bdrs;
  margin-right: 8px;
  padding-left: 0;

  .custom-control-label {
    padding: 8px 12px;
    color: #fff;

    .bi {
      position: relative;
      top: -1px;
      margin-right: 2px;
    }

    &:before {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 6px;
    }

    &--green {
      &:before {
        background: #8FC24F;
      }
    }

    &--blue {
      &:before {
        background: #2B3057;
      }
    }

    &--orange {
      &:before {
        background: #F45800;
      }
    }
  }

  .custom-control-input:checked~.custom-control-label::after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 6px;
    background: none;
  }
}

.footer {
  border-top: 1px solid $line;
  background: #FAFAFA;

  &__top {
    padding: 30px 0;
  }

  &__bottom {
    padding: 15px 0;
    border-top: 1px solid $line;
    font-size: 0.8rem;
  }
}

.footer-menus {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.footer-menu {
  padding: 15px;

  &__title {
    display: block;
    margin-bottom: 10px;
    color: $secondary;
    font-size: 1.1rem;
  }

  &__list {
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 4px 0;
  }

  &__link {
    padding: 4px 0;
    color: $text;
    text-decoration: none;
    font-weight: 400;
    font-size: 0.9rem;

    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
}

.left-menu {
  padding: 0 30px 30px;
  list-style: none;

  a {
    @include efekt(color);
    display: block;
    padding: 5px 0;
    color: $text;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;

    &.active,
    &:Hover {
      color: $primary;
    }
  }

  .open-next {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bi {
      width: 10px;
      height: 10px;
    }
  }
}

.sub-menu {
  display: none;
  padding-left: 0;
  padding-left: 15px;
  border-left: 1px solid #dfdfdf;
  list-style: none;

  a {
    font-weight: 400;
    font-size: 0.9rem;

    .bi{
      width: 12px;
      height: 12px;
    }
  }

  &--level-2 {
    padding-left: 15px;
    border-left: 1px solid #dfdfdf;
  }

  &--level-3 {
    padding-left: 15px;
    border-left: 1px solid #dfdfdf;
  }
}

.detail-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.left-side {
  width: 350px;
  background: #f4f4f4;

  &__search {
    padding: 30px;
  }

  &__title {
    padding: 20px 50px;
    background: #D6D6D6;
    color: $secondary;
    font-weight: 600;
    font-size: 1.1rem;
  }
}

.content-wrapper {
  width: calc(100% - 350px);
}

.left-link {
  width: calc(100% - 18px);
}

.search-wrapper {
  position: relative;

  .form-control {
    padding-right: 40px;
  }

  .bi {
    @extend .dikeyAbsolute;
    right: 15px;
    color: $primary;
  }
}

.banner {
  position: relative;
  padding: 250px 50px 15px;
  color: #fff;

  &__link {
    position: absolute;
    top: 50px;
    left: 50px;
  }

  &__title {
    display: block;
  }

  &--map {
    padding-top: 45vh;
    background: #f4f4f4 !important;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 3;
      width: 100%;
      height: 200px;
      background: linear-gradient(transparent, rgba(#000000, 0.8));
      content: '';
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    .banner__title,
    .breadcrumb {
      position: relative;
      z-index: 4;
    }
  }
}

.breadcrumb {
  margin: 0;
  padding: 0;
  background: none;
  font-size: 0.9rem;

  &__divider {
    margin: 0 6px;
  }

  &__item {
    color: #fff;
    text-decoration: none;
  }

  a.breadcrumb__item {
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
}

.content {
  padding: 50px;
  background: #fff;
}

.text-content {
  p {
    margin-bottom: 20px;
  }
}

.about-cols {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px solid $line;

  &__item {
    padding: 50px;
    width: 50%;

    &:first-child {
      border-right: 1px solid $line;
    }
  }

  &__link {
    margin-right: auto;
    margin-left: auto;
    padding-top: 30px;
    width: 380px;
    border-top: 1px solid $line;
    text-align: center;
  }
}

.blog {
  height: 100%;
  background: #fff;

  &__img {
    img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      object-fit: cover;
    }
  }

  &__content {
    position: relative;
    padding: 30px 30px 90px;
    border: 1px solid $line;
    border-top: none;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &__title {
    @include efekt(color);
    display: block;
    margin-bottom: 10px;
    color: $secondary;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.2rem;

    &:Hover {
      color: $primary;
    }
  }

  &__bottom {
    @extend .flex;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px;
    width: 100%;
  }

  &__date {
    font-size: 0.8rem;
  }

  &--big {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .blog__img {
      width: 50%;

      img {
        border-radius: 0;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }

    .blog__content {
      width: 50%;
      border-top: 1px solid $line;
      border-left: none;
      border-top-right-radius: 6px;
      border-bottom-left-radius: 0;
    }
  }
}

.contact-form {
  padding: 50px;
}

.address-box {
  &__label {
    display: block;
    margin-bottom: 4px;
    color: $primary;
    font-weight: 500;
    font-size: 0.9rem;
  }

  &__link {
    @include efekt(color);
    color: $secondary;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      color: $primary;
    }

    &--big {
      font-size: 1.8rem;
    }
  }

  &__item {
    font-weight: 400;
  }

  .social__link {
    color: $secondary;

    .bi {
      width: 24px;
      height: 24px;
    }

    &:Hover {
      color: $primary;
    }
  }
}

.home-form {
  &--contact {
    padding-top: 50px;
    border-top: 1px solid $line;
  }
}
@import 'style1600', 'style1400', 'mobil-nav', 'style1200', 'style992', 'style768', 'style576';
