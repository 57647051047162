$azo: 'Rubik', sans-serif;

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

a{
  color: $primary;

  &:hover{
    color: $hover;
  }
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  background: #fff;
  color: $text;
  font-family: $azo;
  line-height: 1.5;
}

.bi{
  vertical-align: middle;
}

iframe,
img,
svg,
video {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}

button,
input,
select,
textarea {
  font-family: $azo;
}

.pos-rel {
  position: relative;
}

.slick-slide {
  outline: transparent !important;

  a {
    outline: transparent !important;
  }
}

.noscroll {
  overflow: hidden;
}

.tab-panel {
  display: none;
}

.tab-active {
  display: block;
  animation: fadeIn 0.5s;
}

.tabcontent {
  animation: fadeIn 0.5s;
}

.fadeIn {
  animation: fadeIn 0.5s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
