.title {
  font-size: 3rem;
  font-weight: bold;
  display: block;
  margin: 0;

  &--big{
    font-size: 4rem;
  }

  &--semi{
    font-size: 3.5rem;
  }

  &--medium {
    font-size: 2.5rem;
  }

  &--smaller {
    font-size: 2rem;
  }
}
