@media (max-width:1200px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }

  .thumb-slider {
    padding: 0 15px;
  }

  .custom-button {
    &--next {
      right: 0;
    }

    &--prev {
      left: 0;
    }

    .bi {
      width: 24px;
      height: 24px;
    }
  }

  .slider-detail__text,
  .slider-detail__title {
    width: 500px;
  }

  .slider-detail__title {
    font-size: 2.4rem;
  }

  .slider-detail__text {
    font-size: 0.9rem;
  }

  .menu {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu__item {
    display: none;

    &:last-child {
      display: inline-block;
    }
  }

  .btn-mobil {
    display: inline-block;
    margin-left: 15px;
  }

  .btn {
    font-size: 0.9rem;

    &--header {
      font-size: 0.9rem;
    }
  }

  .logo {
    width: 80px;
  }

  .header__bottom .container-fluid {
    padding: 10px 15px;
  }

  .top-buttons__link {
    font-size: 0.8rem;

    .bi {
      width: 12px;
      height: 12px;
      position: relative;
      top: -1px;
    }
  }

  .dropdown-menu {
    font-size: 0.8rem;
  }

  .title {
    font-size: 2.6rem;

    &--big {
      font-size: 3rem;
    }

    &--semi {
      font-size: 2.8rem;
    }

    &--medium {
      font-size: 2rem;
    }

    &--smaller {
      font-size: 1.7rem;
    }
  }

  .service__title {
    font-size: 1.1rem;
  }

  .text {
    font-size: 0.9rem;
  }

  .text--big{
    font-size: 1rem;
  }

  .home-about__content {
    padding: 60px 30px;
  }

  .news-list__item--big .news-list__title,
  .news-list__title {
    font-size: 1rem;
  }

  .news-list__date {
    font-size: 0.7rem;
  }

  .news-list .text,
  .news-list__item--big .news-list__content .text {
    font-size: 0.9rem;
  }

  .btn--bigger {
    font-size: 1.2rem;
  }

  .detail-page{
    flex-direction: column-reverse;
  }

  .left-side{
    width: 100%;
  }

  .content-wrapper{
    width: 100%;
  }

  .content{
    padding: 30px 15px;
  }

  .banner{
    padding: 200px 15px 15px;
  }

  .banner__link{
    top: 15px;
    left: 15px;
  }

  .left-side__search{
    padding: 15px;
  }

  .left-side__title{
    padding: 20px 15px;
  }

  .left-menu{
    padding: 0 15px 15px;
  }

  .about-cols__item{
    padding: 30px;
  }

  .about-cols__link{
    padding-top: 30px;
  }
}
