@media (max-width: 768px) {
  body {
    font-size: 0.9rem;
  }

  .form-box {
    padding: 50px 15px 15px;
  }

  .slider-detail__text,
  .slider-detail__title {
    width: 400px;
  }

  .slider-detail__title {
    font-size: 1.8rem;
    margin-bottom: 30px;
    width: 100%;
    line-height: 1.2;
  }

  .slider-detail__text {
    display: none;
  }

  .slider-detail {
    left: 45px;
    width: calc(100% - 90px);
  }

  .top-buttons__item {
    display: none;

    &:last-child {
      display: inline-block;
    }
  }

  .title {
    font-size: 2.2rem;

    &--big {
      font-size: 2.5rem;
    }

    &--semi {
      font-size: 2.4rem;
    }

    &--medium {
      font-size: 1.8rem;
    }

    &--smaller {
      font-size: 1.5rem;
    }
  }

  .news-list__item--big .news-list__content .text, .news-list .text{
    font-size: .8rem;
  }

  .footer-menu__title{
    font-size: 1rem;
  }

  .footer-menu__link{
    font-size: .8rem;
  }

  .about-cols{
    border-top: none;
  }

  .about-cols__item{
    padding: 30px 15px;
    width: 100%;
    border: 1px solid $line;

    &:first-child{
      border-bottom: none;
    }
  }

  .about-cols__link{
    padding-top: 15px;
    margin-top: 60px;
    position: relative;
    width: 140px;

    &:before{
      content: '';
      position: absolute;
      top: -61px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 1px;
      background: $line;
      height: 61px;
    }
  }

  .address-box__link--big{
    font-size: 1.4rem;
  }
}
