@media (max-width: 576px) {
  .title {
    font-size: 1.8rem;

    &--big {
      font-size: 2.2rem;
    }

    &--semi {
      font-size: 2.1rem;
    }

    &--medium {
      font-size: 1.6rem;
    }

    &--smaller {
      font-size: 1.4rem;
    }
  }

  .mb-5 {
    margin-bottom: 2rem !important;
  }

  .mt-5 {
    margin-top: 2rem !important;
  }

  .my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .news-list__img {
    width: 100%;

    a {
      height: auto;
    }
  }

  .news-list__content {
    margin-top: 10px;
    width: 100%;
  }

  .icon-link {
    font-size: 0.8rem;

    .bi {
      width: 20px;
      height: 20px;
    }
  }

  .footer-menu {
    padding: 15px 0;
    width: 100%;
  }

  .blog--big {
    .blog__img {
      width: 100%;

      img {
        border-radius: 0;
        border-top-right-radius: 6px;
        border-top-right-radius: 6px;
      }
    }

    .blog__content {
      width: 100%;
      border-top: none;
      border-left: 1px solid $line;
      border-radius: 0;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  .blog__content {
    padding: 15px 15px 60px;
  }

  .blog__bottom{
    padding: 15px;
  }

  .blog__title{
    font-size: 1rem;
  }
}
