$text: #696969;
$primary: #00A8E2;
$hover: #0088b8;
$secondary: #2B3057;
$line: #eee;
$dark: #212121;
$color_black: rgba(0, 0, 0, .1);
$color_black-2: rgba(0, 0, 0, .2);
$color_black-3: rgba(0, 0, 0, .3);
$color_black-4: rgba(0, 0, 0, .4);
$color_white: rgba(255, 255, 255, .1);
$color_white-2: rgba(255, 255, 255, .2);
$color_white-3: rgba(255, 255, 255, .3);
$color_white-4: rgba(255, 255, 255, .4);
$color_white-8: rgba(255, 255, 255, .8);

.color--primary {
  color: $primary;
}

.color--text {
  color: $text;
}

.color--dark {
  color: $dark;
}

.color--secondary{
  color: $secondary;
}
