.btn {
  display: inline-block;
  padding: 0.375rem 1.75rem;
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: transparent;
  color: $text;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  &--big {
    padding: 0.675rem 2rem;
    font-size: 1.2rem;
  }

  &--bigger {
    padding: 0.875rem 2rem;
    font-size: 1.4rem;
  }

  &--wide {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  &--header {
    padding: 12px 22px;
    font-size: 1.1rem;
  }

  .bi {
    position: relative;
    top: -2px;
  }
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
}

.btn-primary {
  border-color: $primary;
  background-color: $primary;
  color: #fff;

  &:hover {
    border-color: $hover;
    background-color: $hover;
    color: #fff;
  }
}

.btn-secondary {
  border-color: $secondary;
  background-color: $secondary;
  color: #fff;

  &:hover {
    border-color: $hover;
    background-color: $hover;
    color: #fff;
  }
}

.btn-outline-light {
  color: $text;
  border-color: #C6C6C6;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  border-color: $hover;
  background-color: $hover;
  color: #fff;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba($hover, .5);
}

.btn-outline-primary {
  border-color: $primary;
  color: #fff;

  &:hover {
    border-color: $primary;
    background-color: $primary;
    color: #fff;
  }
}

.btn-outline-primary--2 {
  border-color: $primary;
  color: $primary;

  &:hover {
    border-color: $primary;
    background-color: $primary;
    color: #fff;
  }
}

.btn-outline-primary--3 {
  border-color: $primary;
  color: $text;

  &:hover {
    border-color: $primary;
    background-color: $primary;
    color: #fff;
  }
}

.btn-group-lg > .btn,
.btn-lg {
  font-size: 1.1rem;
}

.btn-group-sm > .btn,
.btn-sm {
  font-size: 0.9rem;
}
